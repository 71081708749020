import React from 'react';

import { Head } from 'src/components/head/head';
import { Layout } from 'src/components/layout/layout';

import './404.scss';

export default () => (
  <>
    <Head title="404 - Not Found" />
    <Layout>
      <h1 className="four-oh-four">404’d!</h1>
      <p>
        Sorry, we can’t seem to find the content you’re looking for.
        <br />
        You can restart your journey by heading <a href="/">back to the homepage</a>.
      </p>
    </Layout>
  </>
);
